.container {
  text-align: center;
  margin-top: 2em;
  padding: 0 10px;
}

.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em;
  cursor: pointer;
  font-size: 2em;
  transition: background-color 0.3s;
  border-radius: 10px; /* Adding some roundness to make it look cute */
}

.item {
  font-size: 2em; /* Make emoji bigger */
}

.grid-item.correct {
  background-color: lightgreen;
}

.grid-item.incorrect {
  background-color: red;
}

.info {
  text-align: center;
  margin-bottom: 1em;
  font-size: 1.2em;
}

.lottie-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
}

.lottie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.lottie-overlay.show {
  opacity: 1;
  visibility: visible;
}